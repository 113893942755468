
/** body **/
.body{
    font-size: var(--font-size-medium);
}
.my-card{
    height: 28rem;
    max-width: 100%;
    border: 1px solid var(--color-dark-grey);
    border-radius: 10px;
    box-shadow: 15px 15px 5px rgba(31, 26, 26, 0.2);
}
.my-card-sm{
    height: 20rem;
    max-width: 100%;
    border: 1px solid var(--color-dark-grey);
    border-radius: 10px;
    box-shadow: 15px 15px 5px rgba(31, 26, 26, 0.2);
    position: relative;
}
.my-card:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: var(--transition-duration) var(--transtion-cubic-bezier);
}
.my-card-sm:hover{
    cursor: pointer;
    transform: scale(.95);
    transition: var(--transition-duration) var(--transtion-cubic-bezier);
}
.my-card-link{
    text-decoration: none;
    color: var(--color-black);
}
.my-card-title{
    text-align: center;
    font-family: var(--font-family-title);
    font-size: var(--font-size-large);
    background-color: var(--color-dark-grey);
    color: var(--color-white);
    height: 20%;
    padding: 20px 4px;
    margin-top: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.my-card-body, .my-card-body-sm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.my-card-body .home, .my-card-body .vs, .my-card-body .away{
    height: 2px;
}
.my-card-body-sm .home, .my-card-body-sm .vs, .my-card-body-sm .away{
    height: 1px;
}
.score-body .home, .score-body .away{
  /*  width: 40px; */
    text-wrap: wrap;
}
.my-card-fotter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-blue-light);
    height: 16.9%;
    margin-top: 5.3rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-right: 5px;
}
.my-card-fotter-sm{
    
    background-color: var(--color-blue-light);
    height: 25%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.my-card-fotter .date{
    padding: 2rem;
}
.my-card-fotter-sm .date{
    position: absolute;
    padding: 0 1rem 1rem 1rem;
}
.my-card-fotter .logoImg{
    max-width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: 50%;
}
.logoImg2{
    width: 80px !important;
    /*height: 40px;*/
    aspect-ratio: 300 / 126;
}
.my-card-fotter-sm .logoImg{
    max-width: 60%;
    height: 60%;
    padding: 2px;
    /*border-radius: 50%;*/
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}
.LoadMore{
    margin: 6rem;
    text-align: center;
}
.btn-more{
    width: 60%;
    border: 1px solid var(--color-dark-grey);
    border-radius: 6rem;
    font-size: var(--font-size-large);
    color: var(--color-white);
    background-color: var(--color-dark-grey);
    padding: 2rem;

}
.btn-more:hover{
    cursor: pointer;
    background-color: var(--color-primary);
}

/** highlight **/
.highlight{
    font-size: var(--font-size-medium);
   
}

.league-title{
    font-family: var(--font-family-title);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    text-align: center;
    background-color: var(--color-dark-grey);
    color: var(--color-white);
    height: 20%;
    padding: 2rem;
    box-shadow: 15px 15px 5px rgba(31, 26, 26, 0.2);
}
.score{
    background: url('../../Assets/images/stadium.webp') center/cover no-repeat;
    height: 18rem;
    width: 100%;
    margin: 2rem 0;
    position: relative;
}
/** make background image more darker **/
.score::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
}
/** on hover make the background-color transparent **/
.score:hover::before{
    background-color: rgba(0,0,0,0);
}
.score-body{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 4rem 4rem 1rem 4rem;
    color: var(--color-white);
    font-size: var(--font-size-large);
}
.score-body .show-score{
    font-size: var(--font-size-large);
    border-radius: 8px;
    height: 47px;
    width: 140px;
    padding: 2px;
    background-color: var(--color-green);
    
}
.score-body .show-score:hover{
    background-color: var(--color-primary);
    cursor: pointer;
    
}

.score-footer{
    color: var(--color-white);
    font-size: var(--font-size-medium);
    text-align: center; 
}
.highlight-video{
    width: 100%;
   /* height: 38.7rem;*/
  /* height: var(--video-player-body-height) !important; */
}

/** tab css */
.tab-body{
    width: 100%;
    /*height: var(--video-player-body-height);*/
    height: 100%;
    position: relative;
    background-color: var(--color-blue-light2);
    
}
.tab-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    
}
.tab-buttons button{
    height: 3rem;
    width: 8rem;
    margin-right: 1px;
    font-weight: var(--font-weight-bold);
}

.tab-active{
    background-color: var(--color-blue-light2);
}
.tab-content{
    display: none;
   
}
.tab-content-show{
    display: flex;
    flex-direction: column;
   
    width: 100%; 
    height: 100%;
}
.react-player{
    padding: 5px;
    margin-bottom: -3px;
    width: 100% !important;
    height: 100% !important;
    flex-grow: 1;
}
.frame-player{
    padding: 5px;
    margin-bottom: -3px;
    width: 100% !important;
    height: 450px !important;
    flex-grow: 1;
}
/** math fact **/
.match-fact-analysis{
    margin-top: 6rem;
}
.match-fact-title{
    font-family: var(--font-family-title);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    text-align: left;
    background-color: var(--color-dark-grey);
    color: var(--color-white);
    padding: 1rem;
    
}
.show-match-fact, .show-match-analysis{
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
}
.show-match-fact:hover, .match-fact-title:hover, .show-match-analysis:hover{
    cursor: pointer;
    color: var(--color-primary)
    
}
.match-fact-body, .match-analysis-body, .match-fact{
    display: none;
    
}
.match-fact-body.show {
    display: block;
    padding-left: 4rem;
}
.match-analysis-body.show{
    display: block;
}
.match-fact.show {
    display: block;
}
.my-table{
    width: 100%;
}

.my-table th:nth-child(1), td:nth-child(1){
    width: 30%;
    height: 3rem;
}
.my-table td:nth-child(2), .my-table td:nth-child(3), .my-table th:nth-child(2), .my-table th:nth-child(3){
    width: 40%;
    height: 3rem;
    text-align: center;
}

/** modal **/
.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
}
.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 101;
}
.popup.show{
    display: flex;
}
.modal{
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 20px;
    width: 50%;
    height: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow-y: scroll;
    z-index: 101;
}
.modal-md{
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 20px;
    width: 70%;
    height: 60%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow-y: scroll;
    z-index: 101;
}
.modal-lg{
    background: var(--color-grass);
    border-radius: 8px;
    width: 75%;
    height: 90%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow-y: scroll; 
}
.modal-lg-bg{
    background: var(--color-white);
    border-radius: 8px;
    width: 75%;
    height: 90%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow-y: scroll; 
}
.modal-xlg{
    background: var(--color-grass);
    border-radius: 8px;
    width: 85%;
    height: 95%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    position: relative;
    overflow-y: scroll; 
}
.close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-red);
    font-size: var(--font-size-x-large);
    margin-bottom: 2rem;
}
.modal-content{
    padding: 2rem;
    width: 100%;
    height: 100%;
}
.popup-content{
    font-size: var(--font-size-medium);
    border: 1px solid var( --color-input-border);
    background: var(--color-white);
    width: 50%;
    
    border-radius: 5px;
    text-align: center;
    position: relative;
}
.popup-hdr{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    
}
.popup-body{
    padding: 10px;
   margin-bottom: 10px;
}
.popup-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-yes, .popup-no{
    margin-right: 20px;
    width: 70px;
    height: 35px;
    border-radius: 10%;
    border-color: var(--color-input-border);
}


.modal-open{
    cursor: pointer;
    text-decoration: underline;
    font-weight: var(--font-weight-bold);
}
.modal-open:hover{
    color: var(--color-primary);
}
.modal-title{
    font-family: var(--font-family-title);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    text-align: center;
    background-color: var(--color-dark-grey);
    color: var(--color-white);
    padding: 1rem;
    width: 100%;
}
.modal-table{
    width: 100%;
}
.modal-table th:nth-child(1),td:nth-child(1){
    width: 5%;
    height: 4rem;
}
.modal-table th:nth-child(n + 2),td:nth-child(n + 2){
    width: 35%;
    height: 3rem;
    text-align: center;
}
.icon-football, .icon-penality{
    color: var(--color-green);
}
.icon-yellow-card{
    color: var(--color-yellow);
}
.icon-red-card{
    color: var(--color-red)
}
.icon-penality.missed{
    color: var(--color-red);
}
.icon-substitution{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.icon-substitution .bx-caret-down, .sub-in{
    color: var(--color-green);
}
.icon-substitution .bx-caret-up, .sub-out, .icon-injury{
    color: var(--color-red);
}

/** lineups **/
.lineups{
    color: var(--color-white-light2);
    position: relative; 
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    margin: auto;
    
}
.best-11{
    border: 2px solid var(--color-white);
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
    position: relative;
}
.penality-aria{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
    width: 40%;
    border-bottom:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);
    position: absolute;
    top: 0;
    left: 30%;
}
.goal-post{
    height: 20px;
    width: 60%;
    border-bottom:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);  
    position: absolute;
    top: 0;
}
.goal-keepper{
    margin: 4px;
   
   
}
.penality-dot{
    width: 5px;
    height: 5px;
    border-radius: 100%;
    border: 4px solid var(--color-white);
    position: absolute;
    top:  25px;
}
.best-10{
    position: relative;
    top: 3.5rem;
   
}
.players-sameline{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.penality-header{
    width: 17%;
    height: 6%;
    border: 2px solid var(--color-white);
    border-color: transparent var(--color-white) var(--color-white);
    border-radius: 0 0 200px 200px; 
    position: absolute;
    left: 42%;
    top: 15%;
    
}
.half-space{
    border-bottom: 2px solid var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    top: 50%;
}
.ball-circle{
    border: 2px solid var(--color-white);
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    position: absolute;
    
}

.penality-aria-up{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 15%;
    width: 40%;
    border-top:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);
    position: absolute;
    bottom: 0;
    left: 30%;
}
.goal-post-up{
    height: 20px;
    width: 60%;
    border-top:2px solid var(--color-white);
    border-left: 2px solid var(--color-white);
    border-right: 2px solid var(--color-white);  
    position: absolute;
    bottom: 0;
}
.penality-dot-up{
    width: 5px;
    height: 5px;
    border-radius: 100%;
    border: 4px solid var(--color-white);
    position: absolute;
    bottom: 25px;
}
.penality-header-up{
    width: 17%;
    height: 6%;
    border: 2px solid var(--color-white);
    border-color:  var(--color-white) var(--color-white) transparent;
    border-radius: 200px 200px 0 0; 
    position: absolute;
    left: 42%;
    bottom: 15%;
}
.player{
    padding: 0 20px;
}
.player-up{
    padding: 50px 20px 0 20px;
}
.player-down{
    padding-bottom: -50px 20px 0 20px;
}
.substitues{
    padding:0 1rem;
}
.sub-title{
    font-family: var(--font-family-title);
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
    text-align: left;
    color: var(--color-white);
    margin-bottom: -2rem;
}
.substitues li{
    height: 1rem;
}

.manager{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1100px;
    padding: 6px;
    color: var(--color-white);
    text-align: center;
}
.formation-outer{
    position: relative;
}
.formation-ok button{
    width: 80px;
    height: 40px;
    border-radius: 10%;
    border-color: var(--color-input-border);
    position: absolute;
    bottom: 6px;
    right: 20px;
}
.formation-ok button:hover{
    background-color: var(--color-primary);
    border-color: var(--color-input-border-focus);
    cursor: pointer;
}

.items-horizontal{
    display: none;
}
.lodaing-overlay-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-large);
}
/************************ about us **********************/
.about-us, .contact-us{
    padding: 10px;
    margin: 30px 0;
    font-size: var(--font-size-medium);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    font-family: var(--font-family-contact);
}
.about-us-title, .contact-us-title{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    transform: rotate(-30deg);
    font-weight: var(--font-weight-bold);
}
.about-us-content{
    text-align: justify;
}
.about-us-content::first-letter{
    font-size: var(--font-size-large);
    font-family: var(--font-family-par); 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
   
}
.about-us-service{
    text-align: center;
    font-weight: var(--font-weight-bold);
}
.serice-icon-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.service-icon-box{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-input-border);
    height: 120px;
    width: 120px;
    border-radius: 50%;
}
.service-icon{
    font-size: var(--font-size-xxx-large);
}
.about-us-sub-title{
    text-align: center;
}
.contact-us-header{
    font-size: var(--font-size-medium);
    text-align: center;
    font-weight: var(--font-weight-bold);
}
.disclaimer{
    font-size: var(--font-size-small)
}
/************************* media quiers *****************/
/* media quieris for mobile size**/
@media (max-width: 768px) {
    .btn-more{
        font-size: var(--font-size-small);
        width: 120%;
    }
     /** media query for highlight *****/
     .items-horizontal{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: scroll;
        /* justify-content: space-between; */
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: var(--font-size-small);
     }
     .highlight .col-1-3{
        display: none;
     }
     .highlight .my-card-sm{
         display: none;
     }
     .highlight .col-3-4{
         width:99%;
     }
    
     .my-card-sm2{
         height: 20rem;
         width: 230px;
         border: 1px solid var(--color-dark-grey);
         border-radius: 10px;
         box-shadow: 15px 15px 5px rgba(31, 26, 26, 0.2);
         position: relative;
         font-size: var(--font-size-small);
         margin: 4px;
     }
     .score{
         height: 10rem;
         margin: 0;
     }
     .score-body{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 2rem 0 2rem;
        font-size: var(--font-size-small);
    }
     .score-body .show-score{
        font-size: var(--font-size-small);
        height: 47px;
        width: 140px;
        margin: 0 8px;
    }
     .score-body .home, .score-body .away{
         width: 180px;
         text-wrap: wrap;
         font-size: var(--font-size-small);
     }
     .score-footer{
        font-size: var(--font-size-small);
    }
    .league-title{
         width: 50px;
         height: 20rem;
         writing-mode: vertical-rl; 
         transform: rotate(180deg);
         font-size: var(--font-size-small);
     }

    .match-fact-title, .show-match-fact, .show-match-analysis, .match-fact p, .my-table{
        font-size: var(--font-size-small);
    }
    .match-fact-body.show {
        padding-left: 2rem;
    }

    .about-us-title, .contact-us-title{
        height: 20px;
        transform: rotate(0deg);
        margin-bottom: -60px;
    }
   
    
}

/** for tablet and small labtops ***/
@media (min-width: 768px) and (max-width: 1024px) {
    /** media query for highlight *****/
    .items-horizontal{
       display: flex;
       flex-direction: row;
       flex-wrap: nowrap;
       width: 100%;
       overflow-x: scroll;
       /* justify-content: space-between; */
       margin: 0;
       padding: 0;
       list-style: none;
    }
    .highlight .col-1-3{
       display: none;
    }
    .highlight .my-card-sm{
        display: none;
    }
    .highlight .col-3-4{
        width:99%;
    }
    .hor-col{
        width: 100%;
        margin: 0 5px;
    }
    .my-card-sm2{
        height: 20rem;
        width: 300px;
        border: 1px solid var(--color-dark-grey);
        border-radius: 10px;
        box-shadow: 15px 15px 5px rgba(31, 26, 26, 0.2);
        position: relative;
        
    }
    .score{
        height: 14rem;
    }
    .score-body .home, .score-body .away{
        width: 180px;
        text-wrap: wrap;
    }
    
    .league-title{
        width: 80px;
        height: 20rem;
        writing-mode: vertical-rl; 
        transform: rotate(180deg);
   
    }

    .popup-content{
        width: 75%;
    }
    .about-us-title, .contact-us-title{
        height: 20px;
        transform: rotate(0deg);
        margin-bottom: -60px;
    }
   
}
@media (min-width: 468px) and (max-width:768px) {
    .popup-content{
        width: 70%;
    }
}
@media (max-width: 468px) {
    .popup-content{
        width: 90%;
    }
    .modal{
        width: 90%;
        height: 60%;
        font-size: var(--font-size-small);
    }
    .modal-title{
        font-size: var(--font-size-medium);
    }
}